import React from 'react';
import { Container } from 'theme-ui';
import SEO from '../components/seo';
import PrimaryLayout from '../components/layout/primary/primary';

const aboutPage: React.FC<{}> = () => (
	<PrimaryLayout>
		<SEO title="about us" />
		<Container py="40px">
			<h1> Our Story </h1>

			<p>It all started in the year 1947 when India got Independence. Narayanaswami Reddy, an entrepreneur in the Town of Tiruvannamalai, Tamil Nadu opened KNR Rice Mill, with a mission of serving the local rice farmers in the surrounding 42 villages to process rice at an affordable price and directly reach the people without middle men.</p>

			<p> For almost seven decades the very same mission is being carried on by KNR family and has expanded to various products like Rice, Millets, Dhal, Flour, oils, Nuts, Spices and other products. Krish Enterprises LLC, USA a subsidiary of KNR Rice Mill has been importing products for various brands for almost 2 decades. KNR Rice Mill’s own brand – Indus Foods is preserving the rich history and commitment by bringing Premium High Quality and Best Variety products, that are sourced directly from the local farmers who adopt natural and traditional ways of growing food. </p>

			<p> We believe ‘Good health comes from Farm and not Pharmacy’. </p>


			<h1> Our Role </h1>

			<p> Indus foods is importer and supplier of food products and we pride ourselves to be able to source directly from small time farmers and supply directly to the consumers. Creating a win-win platform and maximizing the revenue potential for the farmers and saving cost for the consumers. Farm to Home products are naturally grown and processed and that is where we play a crucial role to ensure the quality is maintained for end consumers.</p>			
		</Container>		
	</PrimaryLayout>
);

export default aboutPage;
